body {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  color: #333;
  /* background-color: #F5F5ED !important; */
 
  overflow: hidden;
  margin: 0;
}
body::-webkit-scrollbar{
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
div,header, section, footer{
  position: relative;
}



.intro .header {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}
.boomBox {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.boom {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-direction: row;
  -webkit-perspective: 1000px;
          perspective: 1000px;
}
.boom .contentTip {
  width: 45vw;
  top: 22vh;
  left: 16.8vw;
  z-index: 999;
  font-family: Arial Black;
  font-weight: 400;
  font-size: 2.4vw;
  color: #FFFFFF;
  padding-bottom: 1.3vw;
  line-height: 3.4vw;
  position: absolute;
}
.layoutLfet {
  height: 100%;
  width: 38%;
  background-color: #EA6666;
  display: flex;
  justify-content: center;
  align-items: self-end;
  position: relative;
  flex-wrap: wrap;
}
.layoutLfet .logoBanner {
  width: 26vw;
}
.layoutLfet .bottom-bar-bom {
  height: 2.5vw;
  line-height: 2.5vw;
  background: rgba(255, 255, 255);
  opacity: 0.7;
  width: 100%;
}
.layout {
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
  -webkit-animation-name: slidein;
          animation-name: slidein;
}
@-webkit-keyframes slidein {
  from {
    opacity: 0.3;
  }
  to {
    opacity: 1;
  }
}
@keyframes slidein {
  from {
    opacity: 0.3;
  }
  to {
    opacity: 1;
  }
}
.headerWrapper .logo {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-name: marginDuration;
          animation-name: marginDuration;
}
@-webkit-keyframes marginDuration {
  from {
    -webkit-transform: translateY(-500px);
            transform: translateY(-500px);
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@keyframes marginDuration {
  from {
    -webkit-transform: translateY(-500px);
            transform: translateY(-500px);
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
.barContent {
  -webkit-animation-duration: 1.5s;
          animation-duration: 1.5s;
  -webkit-animation-name: bulidIn;
          animation-name: bulidIn;
}
@-webkit-keyframes bulidIn {
  from {
    -webkit-filter: blur(5px);
            filter: blur(5px);
  }
  to {
    -webkit-filter: blur(0);
            filter: blur(0);
  }
}
@keyframes bulidIn {
  from {
    -webkit-filter: blur(5px);
            filter: blur(5px);
  }
  to {
    -webkit-filter: blur(0);
            filter: blur(0);
  }
}
@-webkit-keyframes opcityCss {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.7;
  }
}
@keyframes opcityCss {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.7;
  }
}
.layout .bottom-bar {
  -webkit-animation: opcityCss 1s linear 1s forwards;
          animation: opcityCss 1s linear 1s forwards;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
  opacity: 0;
}
@keyframes opcityCss {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.7;
  }
}
.layoutLfet .bottom-bar-bom {
  -webkit-animation: opcityCss 1s linear 0.5s forwards;
          animation: opcityCss 1s linear 0.5s forwards;
  -webkit-animation-delay: 1.2s;
          animation-delay: 1.2s;
  opacity: 0;
}
.banner .descLeft1 {
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-name: leftDura;
          animation-name: leftDura;
}
.banner .descLeft2 {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-name: leftDura;
          animation-name: leftDura;
}
.banner .descLeft3 {
  -webkit-animation-duration: 1.5s;
          animation-duration: 1.5s;
  -webkit-animation-name: leftDura;
          animation-name: leftDura;
}
.banner .descLeft4 {
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-name: leftDura;
          animation-name: leftDura;
}
@-webkit-keyframes leftDura {
  from {
    margin-left: -20vw;
  }
  to {
    margin-left: 0;
  }
}
@keyframes leftDura {
  from {
    margin-left: -20vw;
  }
  to {
    margin-left: 0;
  }
}
.contentTip {
  /* 应用动画 */
  -webkit-animation: rotate 1s linear 1;
          animation: rotate 1s linear 1;
  /* 2s是动画持续时间，linear是动画速度曲线，infinite表示无限循环 */
  /* 为了确保旋转是3D的，需要设置perspective属性（可选） */
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  /* 设置元素面向观察者（可选），以确保在旋转时始终可见 */
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  /* 设置旋转中心（可选），这里默认是中心 */
  -webkit-transform-origin: center center;
          transform-origin: center center;
}
@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg);
  }
  to {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
  }
}
@keyframes rotate {
  from {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg);
  }
  to {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
  }
}
.word-download {
  -webkit-animation: borderStyle 4s infinite ease-in-out;
          animation: borderStyle 4s infinite ease-in-out;
}
@-webkit-keyframes borderStyle {
  0% {
    opacity: 0.6;
    box-shadow: 10px 5px 5px #000;
  }
  50% {
    opacity: 1;
    box-shadow: none;
  }
  100% {
    opacity: 0.6;
    box-shadow: 10px 5px 5px #000;
  }
}
@keyframes borderStyle {
  0% {
    opacity: 0.6;
    box-shadow: 10px 5px 5px #000;
  }
  50% {
    opacity: 1;
    box-shadow: none;
  }
  100% {
    opacity: 0.6;
    box-shadow: 10px 5px 5px #000;
  }
}
.layout {
  width: 68%;
  height: 100%;
  overflow: hidden;
  text-align: right;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background: url(../../static/media/layoutBg.9bd7a17c.png) 100% 100% / auto 100% no-repeat;
  background-position: 0 0;
  background-size: cover;
}
.layout .bottom-bar {
  font-family: PingFang SC;
  font-weight: 600;
  height: 2.5vw;
  line-height: 2.5vw;
  font-size: 0.7vw;
  color: #0F0F10;
  text-align: left;
  background: rgba(255, 255, 255);
  opacity: 0;
  width: 100%;
  padding-left: 6vw;
}
header {
  position: relative;
  width: 100%;
}
.headerWrapper {
  position: relative;
  padding-bottom: 7vh;
  text-align: left;
  padding-left: 16.8vw;
  margin-top: 7vh;
}
.headerWrapper .logo {
  width: 17.1vw;
}
.downBoom {
  text-align: right;
  height: 100%;
  padding-top: 66vh;
  padding-right: 17vw;
}
.downBoom .line {
  color: #FFA25F;
  font-size: 0.9vw;
  margin: 0 0.5vw;
}
.downBoom .desc {
  font-family: Arial Black;
  font-weight: 400;
  font-size: 0.9vw;
  color: #FFFFFF;
  line-height: 1.6vw;
  margin-bottom: 1.6vw;
}
.word-download {
  background: #FFFFFF;
  border-radius: 0.2vw;
  font-family: Roboto;
  font-weight: bold;
  font-size: 1.3vw;
  color: #0F0F10;
  line-height: 1.5vw;
  padding: 0.6vw 1.5vw;
  text-decoration: none;
  transition: 1.5s;
}
.word-download:hover {
  margin-right: 2.5vw;
}
.banner {
  position: relative;
  width: 100%;
  margin-top: 10vh;
}
.banner .bgWrapper {
  position: relative;
  width: 100%;
  text-align: left;
  display: block;
}
.banner .bgWrapper .content {
  position: relative;
  z-index: 1;
  margin-bottom: 2.3vw;
}
.banner .bgWrapper .content .title {
  width: 30vw;
}
.banner .bgWrapper .content .newDesc {
  color: #FFFFFF;
  margin-top: 0.3vw;
  padding-left: 16.8vw;
  font-size: 1vw;
  line-height: 1.7vw;
  font-family: Arial Black;
}
.banner .bgWrapper .content .contentBg {
  width: 15vw;
}
.banner .bgWrapper .content .banner-btn {
  width: 17.1vw;
  margin-top: 1vw;
  height: 4.6vw;
  line-height: 3vw;
  border: 0;
  font-size: 1.2vw;
  font-family: DIN Condensed;
  font-weight: 600;
  color: #1E7C51;
  opacity: 1;
  border-radius: 1vw;
}
.banner .bgWrapper .content .banner-btn a {
  color: #1E7C51;
}
.banner .bgWrapper .content .banner-btn .banner-img {
  margin-right: 0.5vw;
  width: 1.2vw;
  height: 0.9vw;
  margin-bottom: 0.3vw;
}
.banner .bgWrapper .imgWrapper {
  z-index: 0;
  margin-top: 2.4vw;
  text-align: center;
}
.banner .bgWrapper .imgWrapper img {
  width: 39vw;
}
.banner-word-box {
  width: 480px;
  height: 400px;
  position: relative;
  display: flex;
  justify-content: flex-start;
}
.banner-word-downloads {
  margin-top: 30px;
  width: 237px;
  position: relative;
  height: 150px;
  color: #fff;
  display: flex;
}
.banner-word-downloads svg {
  width: 17px;
  height: 17px;
  fill: #fff;
  margin-right: 10px;
}
.banner-word-downloads div {
  position: absolute;
  top: 24px;
  left: 20px;
  z-index: 4;
  color: #fff;
  font-family: DIN Condensed;
  font-size: 18px;
  font-weight: bold;
  line-height: 21px;
  color: #ffffff;
  opacity: 1;
}
.banner-imgWrapperMobile {
  margin: 0 auto;
  width: 100%;
  text-align: center;
}
.banner-imgWrapperMobile img {
  width: 80%;
  height: 80%;
}
.banner-img-wrapper {
  width: 60%;
  height: 100%;
}
.banner-text-wrapper {
  width: 40%;
  padding-right: 114px;
  height: 265px;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
}
.banner-text-wrapper h1 {
  font-size: 46px;
}
.banner-text-wrapper p {
  font-size: 16px;
  margin: 50px 0 60px;
  font-weight: 300;
}
@media (min-width: 1680px) {
  .banner .bgWrapper .content .title {
    width: 25vw;
  }
}
.block {
  position: relative;
  width: 100%;
}
.footer {
  width: 100%;
  display: block;
  margin-top: 10vh;
}
.footer .bar {
  overflow: hidden;
  padding-left: 16.8vw;
}
.footer .barContent {
  text-align: left;
  opacity: 1;
}
.footer .barContent .one-center {
  font-family: PingFang SC;
  font-weight: 400;
  position: relative;
}
.footer .barContent .one-center .title {
  color: #F5C56B;
  font-family: Arial Black;
  font-weight: 400;
  font-size: 0.9vw;
  line-height: 1.4vw;
  padding-bottom: 0.1vw;
}
.footer .barContent .one-center .infos {
  margin-bottom: 0.6vw;
  font-family: Arial Black;
  font-weight: 400;
  font-size: 0.9vw;
  color: #FFFFFF;
  line-height: 1.4vw;
}
.footer .barContent .one-center .infos a {
  color: #FFFFFF;
  font-size: 0.9vw;
  padding-bottom: 0.3vw;
  text-decoration: none;
}
.footer .barContent .one-center .infos a:hover {
  text-decoration: underline;
  -webkit-text-decoration-color: #fff;
          text-decoration-color: #fff;
}
.footer .barContent .one-center-end {
  text-align: left;
}
.footer .barContent .one-center-end .infos a {
  color: #000000;
  text-align: left;
  font-family: PingFang SC;
  font-weight: 600;
  font-size: 1.3vw;
  -webkit-text-decoration-color: #fff !important;
          text-decoration-color: #fff !important;
}
.footer .barContent .one-center-end .infos:last-child {
  margin-bottom: 0;
}
.footer .barIcon {
  width: 9.8vw;
}

#reat-content {
  font-family: PingFang SC;
}
.intro .header {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}
.boom-mobile {
  position: absolute;
  left: 0;
  right: 0;
  padding: 0;
  border: 0;
  margin: 0 auto;
  overflow-x: hidden;
  height: 100vh;
  background-color: #EA6666;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column-reverse;
  justify-content: flex-end;
}
.boom-mobile .downBoom {
  text-align: center;
  height: 100%;
  padding-top: 0;
  padding-right: 0;
  position: absolute;
  z-index: 999;
  width: 100%;
  top: 38vw;
}
.boom-mobile .downBoom .line {
  color: #FFA25F;
  font-size: 0.9vw;
  margin: 0 0.5vw;
}
.boom-mobile .downBoom .desc {
  font-family: Arial Black;
  font-weight: 400;
  font-size: 3.2vw;
  color: #FFFFFF;
  line-height: 4vw;
  margin-bottom: 1.2vw;
  padding: 0 12vw;
}
.boom-mobile .downBoom .word-download {
  z-index: 3;
  background: #FFFFFF;
  border-radius: 0.5vw;
  text-decoration: none;
  font-family: Roboto;
  font-weight: 600;
  font-size: 4vw;
  color: #0F0F10;
  margin: 0 auto;
  padding: 1.8vw 3.5vw;
}
.boom-mobile .layoutBg {
  position: relative;
  width: 100%;
  left: 0;
  top: 0;
}
.bodyMob {
  width: 100%;
  height: 42vh;
  overflow: hidden;
  margin-top: 30vw;
}
.banner-mobile {
  position: relative;
  margin-top: 2vw;
}
.block-mobile {
  position: relative;
}
.header-mobile {
  position: relative;
}
.headerWrapper-mobile {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  padding: 0 4.5vw !important;
  width: 100%;
  background-color: #EA6666;
}
.headerWrapper-mobile .contentTipMobile {
  font-size: 6vw;
  color: #FFFFFF;
}
.headerWrapper-mobile .logo {
  width: 34.6vw;
}
.headerWrapper-mobile .popover-menu {
  width: 300px;
}
.headerWrapper-mobile .popover-menu button.lang {
  margin: 4.2vw auto;
  float: none;
}
.headerWrapper-mobile .popover-menu div.version {
  margin: 8.5vw auto 4.2vw;
  float: none;
}
.headerWrapper-mobile .popover-menu .ant-popover-inner {
  padding: 0;
  overflow: hidden;
}
.headerWrapper-mobile .popover-menu .ant-popover-inner-content {
  padding: 0;
}
.headerWrapper-mobile .nav-phone-menu {
  fill: white;
  width: 5.3vw;
  height: 5.3vw;
  cursor: pointer;
}
.banner-mobile {
  position: relative;
  overflow: hidden;
  width: 100%;
  overflow-x: hidden;
}
.banner-mobile .bgWrapper {
  position: relative;
  margin: 0 auto;
  text-align: left;
  height: 100%;
}
.banner-mobile .bgWrapper .content {
  position: relative;
  z-index: 1;
  height: 100%;
  font-family: Impact;
  font-weight: 400;
  color: #CBC7AE;
}
.banner-mobile .bgWrapper .content .contentBg {
  width: 30vh;
  z-index: 0;
}
.banner-mobile .bgWrapper .content .title {
  height: 25vh;
}
.banner-mobile .bgWrapper .content .contentTip {
  font-family: Arial Black;
  font-weight: 400;
  font-size: 4.6vw;
  color: #FFFFFF;
  line-height: 6.4vw;
  margin-bottom: 4vw;
}
.banner-mobile .bgWrapper .content .subtitle {
  font-size: 3.6vw;
  display: flex;
  align-items: center;
  justify-content: start;
}
.banner-mobile .bgWrapper .content .newDesc {
  text-align: center;
  font-size: 3.6vw;
  margin-top: 1.7vw;
}
.banner-mobile .bgWrapper .content .desc {
  font-family: Arial Black;
  font-weight: 400;
  font-size: 2.9vw;
  color: #FFFFFF;
  line-height: 2.6vw;
  margin-bottom: 1.5vw;
}
.banner-mobile .bgWrapper .content .line {
  color: #FFA25F;
  font-size: 2.9vw;
  margin: 0 1.5vw;
}
.banner-mobile .bgWrapper .content .banner-btn {
  width: 60vw;
  position: relative;
  text-align: center;
  margin: 0 auto;
  border: 0;
  opacity: 1;
  border-radius: 1.2vw;
  font-size: 4vw;
  font-family: PingFang SC;
  font-weight: 600;
  line-height: 4.6vw;
  margin-bottom: 12vw;
  color: #FFFFFF;
}
.banner-mobile .bgWrapper .content .banner-btn a {
  color: #1E7C51;
}
.banner-mobile .bgWrapper .content .banner-btn .banner-img {
  width: 3.7vw;
  height: 3.7vw;
  margin-right: 0.3vw;
  margin-bottom: 0.7vw;
}
.banner-mobile .bgWrapper .imgWrapper {
  position: relative;
  z-index: 0;
  margin-top: -35vh;
  left: 0;
  right: 0;
  width: 100%;
  text-align: center;
}
.banner-mobile .bgWrapper .imgWrapper img {
  width: 100vw;
}
.banner-mobile-ims {
  position: absolute;
  left: -0.8vw;
  top: 0;
  width: 100%;
  height: 40vw;
  z-index: 3;
}
.banner-mobile-andriod {
  width: 4.5vw;
  height: 5.3vw;
  margin: -1.3vw 2.6vw 0 1.3vw;
}
.banner-mobile-newIcon {
  width: 12.5vw;
  height: 12.5vw;
  position: absolute;
  top: -4.2vw;
  right: -2.1vw;
  z-index: 999;
}
.banner-mobile-imgWrapperMobile {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  text-align: center;
}
.banner-mobile-imgWrapperMobile img {
  width: 80%;
  height: 80%;
}
.banner-mobile-img-wrapper {
  width: 60%;
  height: 100%;
}
.banner-mobile-text-wrapper {
  width: 40%;
  padding-right: 13.7vw;
  height: 70.6vw;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
}
.banner-mobile-text-wrapper h1 {
  font-size: 46px;
}
.banner-mobile-text-wrapper p {
  font-size: 4.2vw;
  margin: 13.3vw 0 16vw;
  font-weight: 300;
}
.block1-mobile {
  overflow: initial;
  position: relative;
  overflow: hidden;
  background-image: linear-gradient(to bottom, #1E213B 30%, #1E213B 70%), url(../../static/media/bg_feature.d20c76db.png);
}
.block1-mobile .topTip {
  width: 100%;
  overflow: hidden;
  text-align: left;
  padding: 8.3vw 7.1vw;
}
.block1-mobile .topTip h2 {
  font-size: 5.6vw;
  font-family: PingFang SC;
  font-weight: 600;
  line-height: 6.5vw;
  color: #7BDFFF;
}
.block1-mobile .topTip img {
  width: 40.5vw;
  height: 4.3vw;
}
.block1-mobile .topTip .title {
  width: auto;
  height: 3.2vw;
  margin-bottom: 1vw;
}
.block1-mobile .topTip div {
  padding-top: 0.8vw;
  font-size: 3.3vw;
  font-weight: 600;
  line-height: 4vw;
  color: #8587C4;
}
.block1-mobile .page-main-content {
  position: relative;
  background-size: cover;
  background-position: center;
  padding: 0 4.5vw;
  padding-top: 4.6vw;
  padding-bottom: 9.3vw;
  margin-top: -1px;
}
.block1-mobile .page-main-feature {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.block1-mobile .page-main-feature .item-feature .cardInfo {
  position: relative;
  border-radius: 2.6vw;
  color: #8587C4;
  background: rgba(255, 255, 255, 0.05);
  padding: 2vw;
  height: 30vh;
  margin-bottom: 5vw;
}
.block1-mobile .page-main-feature .item-feature .cardInfo .cardTitle {
  width: 29.4vw;
  font-size: 3.6vw;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 4.6vw;
  opacity: 1;
  color: #7BDFFF;
  margin: 2.8vw 0;
}
.block1-mobile .page-main-feature .item-feature .cardInfo .cardDetail {
  width: 28.5vw;
  font-size: 2.8vw;
  font-family: PingFang SC;
  font-weight: 600;
  line-height: 4.6vw;
  opacity: 1;
  margin-bottom: 5.3vw;
}
.block1-mobile .page-main-feature .item-feature .card0 {
  text-align: center;
}
.block1-mobile .page-main-feature .item-feature .card0 .cardDetail {
  line-height: 4.3vw;
  font-size: 2vw;
}
.block1-mobile .page-main-feature .item-feature .card1 {
  text-align: center;
}
.block1-mobile .page-main-feature .item-feature .card1 .cardDetail {
  font-size: 2vw;
  line-height: 4.3vw;
}
.block1-mobile .page-main-feature .item0 {
  margin-right: 4.3vw;
}
.block1-mobile .page-main-feature .item0 .cardIcon {
  width: 5.9vw;
  height: 10.2vw;
}
.block1-mobile .page-main-feature .item1 .cardIcon {
  width: 8vw;
  height: 10.2vw;
}
.block1-mobile .page-main-feature .item2 {
  margin-right: 4.3vw;
}
.block1-mobile .page-main-feature .item2 .cardIcon {
  width: 7vw;
  height: 10.2vw;
}
.block1-mobile .page-main-feature .item2 .cardInfo .cardDetail {
  line-height: 4.6vw;
}
.block1-mobile .page-main-feature .item3 .cardIcon {
  width: 10.9vw;
  height: 10.2vw;
}
.footer-mobile {
  overflow: hidden;
  width: 100%;
}
.footer-mobile .one-center {
  font-family: PingFang SC;
  font-weight: 600;
  position: relative;
  text-align: center;
}
.footer-mobile .one-center .title {
  font-size: 4vw;
  font-weight: bold;
  font-family: Arial Black;
  font-weight: 400;
  opacity: 1;
  color: #F5C56B;
}
.footer-mobile .one-center .infos {
  height: 6.4vw;
  opacity: 1;
  border-radius: 1.8vw;
  width: 58vw;
  margin: 0 auto;
  font-size: 3.4vw;
  font-family: Arial Black;
  font-weight: 400;
  line-height: 6.4vw;
  margin-top: 1vw;
  color: #FFFFFF;
}
.footer-mobile .one-center .infos a,
.footer-mobile .one-center .infos div {
  font-size: 3.3vw;
  line-height: 4.1vw;
  text-align: center;
  font-weight: 400;
  color: #FFFFFF;
  -webkit-text-decoration-color: #fff;
          text-decoration-color: #fff;
}
.footer-mobile .one-center-end {
  opacity: 1;
  text-align: center;
  padding: 3vw 9vw;
}
.footer-mobile .one-center-end .title {
  font-size: 4vw;
  font-family: Arial Black;
  font-weight: 600;
  line-height: 4vw;
  color: #F5C56B;
  padding-bottom: 2.5vw;
}
.footer-mobile .one-center-end .infos {
  position: relative;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
}
.footer-mobile .one-center-end .infos div {
  line-height: 4vw;
  font-size: 3.4vw;
  font-family: Arial Black;
  font-weight: 400;
  -webkit-text-decoration-color: #fff;
          text-decoration-color: #fff;
}
.footer-mobile .one-center-end .infos .contImg {
  position: relative;
  width: 3.7vw;
  height: 2.6vw;
  margin-right: 1.8vw;
  margin-top: 1vw;
}
.layoutLfet-mobile {
  background-color: #EA6666;
}
.layoutLfet-mobile .logoBanner-mobile {
  width: 28vh;
}
.layoutLfet-mobile header {
  width: 0;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: #7BDFFF;
  color: #fff;
}
.ant-menu-item::after {
  border-right: 1vw solid #7BDFFF !important;
}
.bottom-bar-mobile {
  font-size: 2.4vw;
  color: #5C5C5C;
  margin: 7vw auto 3vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contentWrapper {
  padding: 20px 18vw;
  margin: auto;
  overflow-x: hidden;
  font-weight: bold;
  font-size: 14px;
  position: fixed;
  color: #000;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  overflow-y: scroll;
  padding-bottom: 3vh;
}
.contentWrapper h2 {
  font-size: 24px;
  text-align: center;
  font-weight: 500;
  color: #000;
}
.contentWrapper h1 {
  font-size: 24px;
  text-align: center;
  font-weight: bold;
  color: #000;
}
.contentWrapper p {
  text-align: left;
  text-indent: 30px;
  font-size: 18px;
  line-height: 30px;
}
.select {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.select .countrySelect {
  background-color: #fff;
  padding: 50px;
  border-radius: 21px;
}
.select .countrySelect .localCountry {
  position: relative;
  border-radius: 21px;
  padding: 10px;
  margin: 10px 14px;
  padding-left: 14px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border: 1px solid #07d0e5;
}
.select .countrySelect .localCountry .countrylogo {
  width: 60px;
}
.select .countrySelect .localCountry .countryName {
  width: 200px;
  font-family: Roboto;
  font-weight: bold;
  font-size: 20px;
  color: #333333;
  padding-left: 0.5vw;
  padding-right: 1vw;
}
@media only screen and (max-width: 830px) {
  .contentWrapper {
    background: #fff;
    padding: 20px;
    width: 100%;
    margin: auto;
    border: 0;
    box-sizing: border-box;
  }
  .contentWrapper h2 {
    font-size: 20px;
    text-align: center;
    color: #333;
    font-weight: 500;
  }
  .contentWrapper p {
    text-align: left;
    text-indent: 30px;
    font-size: 12px;
    line-height: 30px;
    color: #666;
  }
}

input {
  border: none !important;
  outline: none !important;
  background-color: rgba(0, 0, 0, 0) !important;
}
textarea {
  border: none !important;
  outline: none !important;
  background-color: rgba(0, 0, 0, 0) !important;
}
.am-modal-body {
  color: #666;
  font-size: 33px !important;
  display: flex !important;
  height: 100% !important;
  align-items: center !important;
  width: 100%;
  justify-content: center !important;
}
.am-list {
  width: 100%;
}
.am-modal-popup-slide-up {
  top: initial;
  bottom: initial;
  width: 100%;
  display: flex !important;
  height: 100% !important;
  align-items: center !important;
  justify-content: center !important;
}
.am-list-content {
  margin: 0 35px;
}
.ant-notification {
  width: auto !important;
  height: auto !important;
  margin: 0 !important;
  top: 0 !important;
}
.ant-notification-notice {
  width: 100vw !important;
  height: 100vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.35);
}
.ant-notification-notice-content {
  padding: 3vh 2vw;
  line-height: initial;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 3vw;
}
.ant-notification-notice-message {
  color: #fff;
  margin: 0 !important;
  padding: 1vh 6vw !important;
  font-size: 2.5vh !important;
}
.ant-notification-notice-icon {
  position: relative !important;
}
.ant-notification-notice-icon-error {
  margin: 0;
  padding: 0;
  font-size: 4.5vh;
  line-height: initial;
  color: #fc5783;
}
.ant-notification-notice-with-icon {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: auto !important;
  line-height: initial !important;
  width: auto !important;
}
.ant-notification-topRight {
  width: 300px;
}
.ant-notification-notice-close-x {
  position: fixed !important;
  left: 0 !important;
  right: 0 !important;
  z-index: 100 !important;
  bottom: 35vh !important;
  width: 4vh;
  height: 4vh;
  line-height: 4vh;
  text-align: center !important;
  margin: 0 auto !important;
  color: #fff !important;
  border: 1px solid #fff;
  border-radius: 50%;
}
.ant-notification-notice-close-x:after {
  font-size: 3vh !important;
  line-height: 4vh;
}
.errorModal {
  position: fixed;
  z-index: 1000;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
}
.errorContent {
  position: relative;
  border-radius: 1.5vh;
  margin: 0 15vw;
  background: #fff;
  overflow: hidden;
}
.loadingContent {
  background-color: rgba(0, 0, 0, 0.5);
}
.errorHeader {
  color: #fb4402;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.2vw 0;
}
.errorMsg {
  color: #fb4402;
  text-align: center;
}
.loadingMsg {
  color: #fff;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  30% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  60% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  30% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  60% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.btn {
  margin: 0 3vw;
  background: #cccccc;
  color: #fff;
  cursor: pointer;
}
.deleteWrapper-mob {
  padding: 35px 0;
  padding-top: 108px;
  position: initial;
  min-height: 70vh;
  height: 100vh;
  display: block;
  background-color: #0f0f10;
}
.deleteWrapper-mob .tabNav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 20vw;
  z-index: 100;
  background-color: #0f0f10;
}
.deleteWrapper-mob .tabNav .title {
  text-align: left;
  font-family: Roboto;
  font-weight: 700;
  color: #ea6666;
  letter-spacing: 0;
  padding-top: 10.7vw;
  font-size: 3.9vw;
  padding-left: 9.3vw;
}
.deleteWrapper-mob .content {
  position: fixed;
  top: 28vw;
  left: 0;
  z-index: 100;
  right: 0;
  padding: 0 9.3vw;
  border-radius: 0 0 2.8vw 2.8vw;
}
.deleteWrapper-mob .desc {
  position: relative;
  display: flex;
  padding-right: 0;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 25px;
  font-family: Roboto;
  font-weight: 400;
  line-height: 38px;
  color: #cccccc;
  text-align: left;
  padding: 0 35px;
}
.deleteWrapper-mob .fbtInfo {
  position: relative;
  opacity: 1;
  background: #f8f8f8;
  padding: 7.3vw 4.6vw;
  padding-top: 9vw;
}
.deleteWrapper-mob .fbtInfo .tip {
  position: absolute;
  text-align: center;
  font-size: 5.6vw;
  font-family: Roboto;
  font-weight: 600;
  color: #ea6666;
  letter-spacing: 0px;
  background: #fff;
  padding: 2.2vw 7vw;
  top: -5.5vw;
  left: 5.6vw;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}
.deleteWrapper-mob .fbtInfo .fbtTitle {
  margin-top: 4.6vw;
  font-family: Roboto;
  font-weight: bold;
  color: #cccccc;
  letter-spacing: 0px;
  margin-left: 0;
  margin-bottom: 2.2vw;
  font-size: 3vw;
  height: 5vw;
  line-height: 5vw;
}
.deleteWrapper-mob .fbtInfo .fbtTitle:first-child {
  margin-top: 0;
}
.deleteWrapper-mob .fbtCol {
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 42px;
  font-size: 3.3vw;
  font-family: Roboto;
  font-weight: bold;
  color: #cccccc;
  letter-spacing: 0px;
  opacity: 1;
  text-align: left;
}
.deleteWrapper-mob .fbtCol .need {
  color: #cccccc;
}
.deleteWrapper-mob .fbtItem {
  border-bottom: 2px solid rgba(204, 204, 204, 0.8);
  margin-top: 0.5vw;
}
.deleteWrapper-mob .fbtPhone {
  padding: 2.2vw 0;
  opacity: 1;
  width: 100%;
}
.deleteWrapper-mob .fbtPhone .fpInfo {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid rgba(136, 138, 149, 0);
}
.deleteWrapper-mob .fbtPhone .fpInfo .fpLabel {
  font-family: Roboto;
  font-weight: bold;
  color: #000;
  letter-spacing: 0px;
  font-size: 3.3vw;
  height: 5vw;
  line-height: 5vw;
}
.deleteWrapper-mob .fbtPhone .fpInfo .fpInput {
  position: relative;
  width: 100%;
  font-family: Roboto;
  caret-color: #cccccc;
  color: #000;
  letter-spacing: 0px;
  height: 5vw;
  line-height: 5vw;
  font-size: 3.3vw;
  margin-left: 2.8vw;
  border: 0;
  font-weight: bold;
}
.deleteWrapper-mob .fbtPhone .fpInfo .fpInput::-webkit-input-placeholder {
  border: 0;
  color: #cccccc;
  height: 5vw;
  line-height: 5vw;
  font-size: 3vw;
}
.deleteWrapper-mob .fbtPhone .fpInfo .fpInput::placeholder {
  border: 0;
  color: #cccccc;
  height: 5vw;
  line-height: 5vw;
  font-size: 3vw;
}
.deleteWrapper-mob .fbtPhone .fpInfo .fpIdCard {
  margin-left: 0;
}
.deleteWrapper-mob .showTips {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 2.2vw;
  margin-bottom: 0.5vw;
  margin-top: 0.5vw;
}
.deleteWrapper-mob .showTips span {
  font-size: 2.4vw;
  color: #fc5783;
}
.deleteWrapper-mob .fbtContentError {
  border-bottom: 2px solid #fc5783;
}
.deleteWrapper-mob .fbtContentError .fpInfo .fpInput {
  color: #000;
}
.deleteWrapper-mob .fbtPhone::-webkit-input-placeholder {
  color: #cccccc;
}
.deleteWrapper-mob .fbtPhone::placeholder {
  color: #cccccc;
}
.deleteWrapper-mob .itemSelect {
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(136, 138, 149, 0);
  opacity: 1;
  padding-top: 2.2vw;
  padding-bottom: 2.2vw;
  font-size: 3.3vw;
  border-bottom: 2px solid rgba(204, 204, 204, 0.8);
}
.deleteWrapper-mob .itemSelect .iSelect {
  border: 0;
  font-size: 3.3vw;
  font-family: Roboto;
  font-weight: bold;
  height: 5vw;
  line-height: 5vw;
  color: #000;
  width: 100%;
  background: initial;
}
.deleteWrapper-mob .itemSelect .itemUppercase {
  text-transform: uppercase;
}
.deleteWrapper-mob .itemSelect .iSelect::-webkit-input-placeholder {
  color: #cccccc;
}
.deleteWrapper-mob .itemSelect .iSelect::placeholder {
  color: #cccccc;
}
.deleteWrapper-mob .itemSelect .itemImg {
  width: 2.6vw;
  height: 2.6vw;
  cursor: pointer;
}
.deleteWrapper-mob .fbtContent {
  width: 100%;
  padding: 1.2vw 1.6vw;
  font-size: 3.3vw;
  font-family: Roboto;
  font-weight: bold;
  color: #fff;
  letter-spacing: 0px;
  opacity: 1;
  border: 2px solid rgba(204, 204, 204, 0.8);
  margin-top: 5vw;
  box-sizing: border-box;
}
.deleteWrapper-mob .fbtContent .fpLabel {
  font-size: 3.3vw;
  font-family: Roboto;
  font-weight: bold;
  color: #cccccc;
  letter-spacing: 0px;
  text-align: left;
}
.deleteWrapper-mob .fbtContent .fpText {
  border: 0 !important;
  font-family: Roboto;
  color: #000;
  caret-color: #cccccc;
  width: 100%;
  height: 12vw;
  font-size: 3.3vw;
  font-weight: bold;
}
.deleteWrapper-mob .fbtContent .fpText::-webkit-input-placeholder {
  color: #cccccc;
  font-size: 3.3vw;
  height: 5vw;
  line-height: 5vw;
  font-family: Roboto;
}
.deleteWrapper-mob .fbtContent .fpText::placeholder {
  color: #cccccc;
  font-size: 3.3vw;
  height: 5vw;
  line-height: 5vw;
  font-family: Roboto;
}
.deleteWrapper-mob .itemSelect::-webkit-input-placeholder {
  color: #cccccc;
}
.deleteWrapper-mob .itemSelect::placeholder {
  color: #cccccc;
}
.deleteWrapper-mob .fbtContentOn {
  opacity: 1;
}
.deleteWrapper-mob .idCard {
  opacity: 0.7;
}
.deleteWrapper-mob .idCard .fpInfo {
  opacity: 0.7;
}
.deleteWrapper-mob .idCard .fpInfo .fpInput {
  opacity: 0.7;
}
.deleteWrapper-mob .agreeWrapper {
  margin-top: 6.9vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.deleteWrapper-mob .agreeWrapper .laCheck {
  position: relative;
  border: 1px solid #7c3a3b;
  width: 3.6vw;
  height: 3.6vw;
}
.deleteWrapper-mob .agreeWrapper .hasCheck {
  border: 0;
}
.deleteWrapper-mob .agreeWrapper .hasCheck .laImg {
  position: absolute;
  height: 2.7vw;
  width: 3.6vw;
  height: 3.6vw;
}
.deleteWrapper-mob .agreeWrapper .laText {
  display: flex;
  margin-left: 2vw;
  color: #0f0f10;
  letter-spacing: 0px;
  font-size: 2.4vw;
  font-family: Roboto;
  font-weight: 600;
  line-height: 3vw;
  cursor: pointer;
}
.deleteWrapper-mob .agreeWrapper .laText .normolColor {
  color: #0f0f10;
}
.deleteWrapper-mob .agreeWrapper .laText .doc {
  text-decoration: underline;
  color: #0f0f10;
  font-weight: bold;
  padding: 0 0.8vw;
}
.deleteWrapper-mob .fbtAction {
  position: absolute;
  left: 0;
  right: 0;
  top: 80vh;
  height: 10vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ea6666;
  font-size: 3.9vw;
  font-family: Roboto;
  font-weight: bold;
  color: #fff;
  letter-spacing: 0px;
  margin: 0 8.3vw;
  z-index: 10;
  opacity: 0.5;
}
.deleteWrapper-mob .fbtActionOn {
  opacity: 1;
}
.deleteWrapper-mob .fbtSuccess {
  position: relative;
  display: flex;
  height: 80vh;
  margin: 0 auto;
  padding: 0 3.5vw;
  justify-content: center;
  align-items: center;
  z-index: 100;
  font-weight: bold;
  letter-spacing: 0px;
  color: #cccccc;
}
.deleteWrapper-mob .fbtSuccess span {
  font-size: 4.8vw;
  text-align: center;
  font-family: Roboto;
}
.deleteWrapperColor-mob {
  padding-top: 0;
  background-color: #f7f7f7;
  color: #cccccc;
}
.deleteWrapperColor-mob .tabNav {
  height: 100vh;
}
.selectModal-mob .modalContent {
  border-radius: 2.8vw 2.8vw 0 0;
  margin: 0 0;
}
.selectModal-mob .modalContent .listHead {
  font-size: 3.9vw;
}
.selectModal-mob .modalContent .list .listItem {
  font-size: 3.9vw;
  margin: 2vw 4.6vw;
}
.copySuccessTip-mob {
  height: 54px;
  align-items: center;
  display: flex;
  padding: 5px 0;
}
.copySuccessTip-mob .iconsmile {
  width: 54px;
  height: 54px;
  fill: #cccccc;
  margin-right: 9px;
}
.copySuccessTip-mob span {
  font-size: 33px;
  font-family: Roboto;
  font-weight: bold;
  color: #cccccc;
  opacity: 1;
}
.edit-mob {
  overflow-y: scroll;
}
.edit-mob .content {
  height: 70vh !important;
  border-radius: 21px;
  top: 28vw;
  z-index: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.selectModal-mob .modalContent {
  border-radius: 2.8vw;
  margin: 0 4.6vw;
}
.selectModal-mob .modalContent .listHead {
  font-size: 3.9vw;
}
.selectModal-mob .modalContent .list .listItem {
  font-size: 3.9vw;
  margin: 2vw 4.6vw;
}
.selectModal-mob .modalContent .list .listItemOn {
  border-bottom: 0;
}
.errorMsg-mob {
  font-size: 4.6vw !important;
  padding: 3.6vw !important;
}
.loader-mob {
  border: 0.5vh solid #fff;
  border-top: 0.5vh solid rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  width: 5vh;
  height: 5vh;
  margin: 0 auto;
  margin-top: 5vh;
  -webkit-animation: spin 0.5s linear infinite;
          animation: spin 0.5s linear infinite;
}
.btn-mob {
  margin: 0 17vw;
  border-radius: 6.9vw;
  font-size: 4.6vw;
  padding: 1vw 4vw;
  margin-bottom: 3.5vw;
  color: #000000;
}

input {
  border: none !important;
  outline: none !important;
  background-color: rgba(0, 0, 0, 0) !important;
}
textarea {
  border: none !important;
  outline: none !important;
  background-color: rgba(0, 0, 0, 0) !important;
}
.am-modal-body {
  color: #666;
  font-size: 33px !important;
  display: flex !important;
  height: 100% !important;
  align-items: center !important;
  width: 100%;
  justify-content: center !important;
}
.am-list {
  width: 100%;
}
.am-modal-popup-slide-up {
  top: initial;
  bottom: initial;
  width: 100%;
  display: flex !important;
  height: 100% !important;
  align-items: center !important;
  justify-content: center !important;
}
.am-list-content {
  margin: 0 35px;
}
.ant-notification {
  width: auto !important;
  height: auto !important;
  margin: 0 !important;
  top: 0 !important;
}
.ant-notification-notice {
  width: 100vw !important;
  height: 100vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.35);
}
.ant-notification-notice-content {
  padding: 3vh 2vw;
  line-height: initial;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 3vw;
}
.ant-notification-notice-message {
  color: #fff;
  margin: 0 !important;
  padding: 1vh 6vw !important;
  font-size: 2.5vh !important;
}
.ant-notification-notice-icon {
  position: relative !important;
}
.ant-notification-notice-icon-error {
  margin: 0;
  padding: 0;
  font-size: 4.5vh;
  line-height: initial;
  color: #fc5783;
}
.ant-notification-notice-with-icon {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: auto !important;
  line-height: initial !important;
  width: auto !important;
}
.ant-notification-topRight {
  width: 300px;
}
.ant-notification-notice-close-x {
  position: fixed !important;
  left: 0 !important;
  right: 0 !important;
  z-index: 100 !important;
  bottom: 35vh !important;
  width: 4vh;
  height: 4vh;
  line-height: 4vh;
  text-align: center !important;
  margin: 0 auto !important;
  color: #fff !important;
  border: 1px solid #fff;
  border-radius: 50%;
}
.ant-notification-notice-close-x:after {
  font-size: 3vh !important;
  line-height: 4vh;
}
.errorModal {
  position: fixed;
  z-index: 1000;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
}
.errorContent {
  position: relative;
  border-radius: 1.5vh;
  margin: 0 15vw;
  background: #fff;
  overflow: hidden;
}
.loadingContent {
  background-color: rgba(0, 0, 0, 0.5);
}
.errorHeader {
  color: #fb4402;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.2vw 0;
}
.errorMsg {
  color: #fb4402;
  text-align: center;
}
.loadingMsg {
  color: #fff;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  30% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  60% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  30% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  60% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.btn {
  margin: 0 3vw;
  background: #cccccc;
  color: #fff;
  cursor: pointer;
}
.deleteWrapper {
  padding: 2.1vh;
  padding-top: 8vh;
  position: relative;
  min-height: 100vh;
  background-color: #0f0f10;
}
.deleteWrapper .tabNav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 70vh;
  z-index: 1;
}
.deleteWrapper .tabNav .title {
  padding-top: 3.5vh;
  font-size: 2.2vw;
  font-family: Roboto;
  font-weight: bold;
  text-align: left;
  color: #ea6666;
  letter-spacing: 0px;
  padding-left: 10vw;
}
.deleteWrapper .content {
  position: fixed;
  z-index: 100;
  top: 10vw;
  height: 72vh;
  left: 0;
  right: 0;
  margin: 0 10vw;
}
.deleteWrapper .desc {
  position: relative;
  display: flex;
  padding-right: 0;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 2.3vh;
  font-family: Roboto;
  font-weight: 400;
  line-height: 2.5vh;
  color: #cccccc;
  text-align: left;
}
.deleteWrapper .fbtInfo {
  position: relative;
  background: #f8f8f8;
  opacity: 1;
  padding: 4vw 3vw 3vw;
}
.deleteWrapper .fbtInfo .tip {
  position: absolute;
  text-align: center;
  margin: 0;
  font-size: 2.4vw;
  font-family: Roboto;
  font-weight: 600;
  background: #fff;
  color: #ea6666;
  letter-spacing: 0px;
  padding: 1vw 2.8vw;
  top: -2.8vw;
  left: 3vw;
  opacity: 1;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}
.deleteWrapper .fbtInfo .fbtTitle {
  margin-top: 5vh;
  font-family: Roboto;
  font-weight: bold;
  color: #cccccc;
  letter-spacing: 0px;
  margin-left: 0;
  margin-bottom: 1.2vw;
  font-size: 2vh;
  height: 2.2vh;
  line-height: 2.2vh;
}
.deleteWrapper .fbtCol {
  position: relative;
  padding-top: 1.4vh;
  padding-bottom: 1.2vh;
  font-size: 2.2vh;
  line-height: 2.5vh;
  font-family: Roboto;
  font-weight: 400;
  color: #cccccc;
  letter-spacing: 0px;
  opacity: 1;
  text-align: left;
  padding-left: 4.5vh;
}
.deleteWrapper .fbtCol .need {
  color: #cccccc;
}
.deleteWrapper .fbtItem {
  border-bottom: 2px solid rgba(204, 204, 204, 0.8);
  margin-top: 0.5vw;
}
.deleteWrapper .fbtPhone {
  padding: 1vh 0;
  opacity: 1;
  width: 100%;
}
.deleteWrapper .fbtPhone .fpInfo {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.deleteWrapper .fbtPhone .fpInfo .fpLabel {
  font-family: Roboto;
  font-weight: bold;
  color: #000;
  letter-spacing: 0px;
  font-size: 2vh;
  height: 2.2vh;
  line-height: 2.2vh;
}
.deleteWrapper .fbtPhone .fpInfo .fpInput {
  position: relative;
  width: 100%;
  font-family: Roboto;
  font-weight: bold;
  caret-color: #cccccc;
  color: #000;
  letter-spacing: 0px;
  height: 3.2vh;
  line-height: 3.2vh;
  font-size: 2vh;
  margin-left: 1.1vw;
}
.deleteWrapper .fbtPhone .fpInfo .fpInput::-webkit-input-placeholder {
  border: 0;
  color: #cccccc;
  font-size: 2vh;
  height: 3.2vh;
  line-height: 3.2vh;
  border-bottom: 1px solid rgba(136, 138, 149, 0);
}
.deleteWrapper .fbtPhone .fpInfo .fpInput::placeholder {
  border: 0;
  color: #cccccc;
  font-size: 2vh;
  height: 3.2vh;
  line-height: 3.2vh;
  border-bottom: 1px solid rgba(136, 138, 149, 0);
}
.deleteWrapper .fbtPhone .fpInfo .fpIdCard {
  margin-left: 0;
}
.deleteWrapper .showTips {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 2.2vw;
  margin-bottom: 0.5vw;
  margin-top: 0.5vw;
}
.deleteWrapper .showTips span {
  font-size: 2.4vh;
  color: #fc5783;
}
.deleteWrapper .showTips img {
  margin-left: 2.2vw;
  width: 2vh;
  height: 2vh;
}
.deleteWrapper .fbtContentError {
  border-bottom: 2px solid #fc5783;
}
.deleteWrapper .fbtContentError .fpInfo .fpInput {
  color: #000;
}
.deleteWrapper .fbtPhone::-webkit-input-placeholder {
  color: #cccccc;
}
.deleteWrapper .fbtPhone::placeholder {
  color: #cccccc;
}
.deleteWrapper .itemSelect {
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid rgba(204, 204, 204, 0.8);
  opacity: 1;
  padding-top: 1vh;
  padding-bottom: 1vh;
  font-size: 2vh;
  margin-bottom: 5vh;
}
.deleteWrapper .itemSelect .iSelect {
  border: 0;
  font-size: 2vh;
  font-family: Roboto;
  font-weight: bold;
  height: 3.2vh;
  line-height: 3.2vh;
  color: #000;
  width: 100%;
  background: initial;
}
.deleteWrapper .itemSelect .itemUppercase {
  text-transform: uppercase;
}
.deleteWrapper .itemSelect .iSelect::-webkit-input-placeholder {
  color: #cccccc;
}
.deleteWrapper .itemSelect .iSelect::placeholder {
  color: #cccccc;
}
.deleteWrapper .itemSelect .itemImg {
  width: 2.2vh;
  height: 2.2vh;
  cursor: pointer;
}
.deleteWrapper .fbtContent {
  width: 100%;
  padding: 0.8vw 1vw;
  font-size: 2vh;
  font-family: Roboto;
  font-weight: bold;
  color: #000;
  letter-spacing: 0px;
  opacity: 1;
  border: 2px solid rgba(204, 204, 204, 0.8);
  box-sizing: border-box;
  margin-top: 2vw;
}
.deleteWrapper .fbtContent .fpLabel {
  font-size: 21px;
  font-family: Roboto;
  font-weight: bold;
  color: #000;
  letter-spacing: 0px;
  text-align: left;
}
.deleteWrapper .fbtContent .fpText {
  border: 0 !important;
  font-family: Roboto;
  font-weight: bold;
  color: #000;
  caret-color: #cccccc;
  padding-top: 0.1vh;
  width: 100%;
  height: 4vh;
  font-size: 2vh;
}
.deleteWrapper .fbtContent .fpText::-webkit-input-placeholder {
  color: #cccccc;
  font-size: 2vh;
  height: 3.2vh;
  line-height: 3.2vh;
}
.deleteWrapper .fbtContent .fpText::placeholder {
  color: #cccccc;
  font-size: 2vh;
  height: 3.2vh;
  line-height: 3.2vh;
}
.deleteWrapper .itemSelect::-webkit-input-placeholder {
  color: #cccccc;
}
.deleteWrapper .itemSelect::placeholder {
  color: #cccccc;
}
.deleteWrapper .fbtContentOn {
  opacity: 1;
}
.deleteWrapper .idCard {
  opacity: 0.7;
}
.deleteWrapper .idCard .fpInfo {
  opacity: 0.7;
}
.deleteWrapper .idCard .fpInfo .fpInput {
  opacity: 0.7;
}
.deleteWrapper .agreeWrapper {
  margin-top: 2.5vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.deleteWrapper .agreeWrapper .laCheck {
  position: relative;
  border: 1px solid #ffffff;
  width: 2.4vh;
  height: 2.4vh;
  margin-top: 2px;
  border-radius: 50%;
  margin-right: 1.1vh;
}
.deleteWrapper .agreeWrapper .hasCheck {
  border: 0;
}
.deleteWrapper .agreeWrapper .hasCheck .laImg {
  position: absolute;
  left: 0;
  top: 0;
  width: 2.4vh;
  height: 2.4vh;
}
.deleteWrapper .agreeWrapper .laText {
  display: flex;
  color: #0f0f10;
  letter-spacing: 0px;
  font-size: 2.1vh;
  font-family: Roboto;
  font-weight: 400;
  line-height: 3.1vh;
  cursor: pointer;
}
.deleteWrapper .agreeWrapper .laText .normolColor {
  color: #0f0f10;
}
.deleteWrapper .agreeWrapper .laText .doc {
  text-decoration: underline;
  color: #0f0f10;
  font-weight: bold;
  padding: 0 1.1vw;
}
.deleteWrapper .fbtAction {
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  font-weight: bold;
  background: #ea6666;
  bottom: 2vh;
  height: 6.8vh;
  font-size: 3.3vh;
  color: #fff;
  letter-spacing: 0px;
  margin: 0 10.4vh;
  cursor: not-allowed;
  opacity: 0.5;
}
.deleteWrapper .fbtActionOn {
  opacity: 1;
  cursor: pointer;
}
.deleteWrapper .fbtSuccess {
  position: relative;
  display: flex;
  height: 80vh;
  z-index: 100;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  font-size: 4.8vh;
  font-family: Roboto;
  font-weight: bold;
  letter-spacing: 0px;
  color: #cccccc;
}
.deleteWrapper .fbtSuccess span {
  font-size: 4.8vw;
  text-align: center;
  font-family: Roboto;
}
.deleteWrapperColor {
  color: #cccccc;
  padding-top: 0;
}
.deleteWrapperColor .tabNav {
  z-index: 0;
  height: 100vh;
}
.selectModal {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 100;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: rgba(0, 0, 0, 0.6);
  justify-content: center;
  align-items: flex-end;
}
.select {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 35px;
  overflow: hidden;
}
.select .countrySelect {
  position: relative;
  background-color: #fff;
  padding: 50px;
  max-width: 80%;
  border-radius: 21px;
}
.select .countrySelect .localCountry {
  position: relative;
  border-radius: 21px;
  padding: 10px;
  margin: 10px 14px;
  padding-left: 14px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border: 1px solid #07d0e5;
}
.select .countrySelect .localCountry .countrylogo {
  width: 60px;
}
.select .countrySelect .localCountry .countryName {
  width: 200px;
  font-family: Roboto;
  font-weight: bold;
  font-size: 20px;
  color: #333333;
  padding-left: 0.5vw;
  padding-right: 1vw;
}
.copySuccessTip {
  height: 54px;
  align-items: center;
  display: flex;
  padding: 5px 0;
}
.copySuccessTip .iconsmile {
  width: 54px;
  height: 54px;
  fill: #cccccc;
  margin-right: 9px;
}
.copySuccessTip span {
  font-size: 33px;
  font-family: Roboto;
  font-weight: bold;
  color: #cccccc;
  opacity: 1;
}
.edit {
  height: auto;
  padding-bottom: 40vh;
}
.list {
  max-height: 60vh;
  overflow: scroll;
  text-align: center;
}
.list .am-list-content {
  overflow: initial !important;
  text-overflow: initial !important;
  white-space: initial !important;
  font-size: 2.1vh !important;
  padding-top: 1.2vh !important;
  padding-bottom: 1.2vh !important;
}
.list .am-radio-input {
  height: 5vw;
}
.list .am-list-item {
  min-height: 4vh !important;
}
.list .am-radio {
  height: 4vh !important;
}
.list .am-radio-inner {
  right: 0.5vh !important;
  top: 2vw !important;
  width: 2.2vh;
  height: 2.2vh;
}
.list .am-radio-inner:after {
  width: 1.2vh;
  height: 2.2vh;
}
.list .listItem {
  text-align: center;
}
.list .listItemTip {
  font-size: 2.1vh;
  color: #999999;
  text-align: center;
}
.list .listItemTip .listItemTSign {
  color: #b92d00;
}
.selectModal {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 100;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: rgba(0, 0, 0, 0.6);
  justify-content: center;
  align-items: center;
}
.modalContent {
  position: relative;
  overflow: hidden;
  width: 100%;
  border-radius: 5px;
  background: #fff;
  margin: 0 3.5vh;
}
.modalContent .listHead {
  font-weight: bold;
  color: #0f0f10;
  border-bottom: 1px solid #0f0f10;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  opacity: 1;
  height: 8vh;
  line-height: 8vh;
  font-size: 2.2vh;
  font-family: Roboto;
}
.modalContent .listHead .icon {
  width: 35px;
  height: 35px;
}
.modalContent .list {
  position: relative;
  overflow: auto;
  text-align: center;
  max-height: 60vh;
}
.modalContent .list .listItem {
  position: relative;
  margin: 5px 69px;
  text-align: center;
  color: #0f0f10;
  font-weight: bold;
  cursor: pointer;
  padding: 1.5vh 0;
  border: 1px solid #0f0f10;
}
.modalContent .list .listItemOn {
  color: #f5c56b;
  background-color: rgba(113, 162, 255, 0);
  border-bottom: 0;
  font-weight: bold;
}
.modalContent .list .listItemOn .listItem {
  position: relative;
  color: #f5c56b;
}
.modalContent .list::-webkit-scrollbar {
  display: block;
  width: 10px;
  height: 40px;
}
.modalContent .list::-webkit-scrollbar-button {
  color: #333333;
}
.modalContent .list::-webkit-scrollbar-thumb {
  background-position: center;
  border-radius: 10px;
  height: 40px;
  background-color: blue;
}
.loader {
  border: 0.5vh solid #fff;
  border-top: 0.5vh solid rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  width: 5vh;
  height: 5vh;
  margin: 0 auto;
  margin-top: 5vh;
  -webkit-animation: spin 0.5s linear infinite;
          animation: spin 0.5s linear infinite;
}
.errorMsg {
  font-size: 2.2vh;
  padding: 3.3vh;
}
.btn {
  margin: 0 8vh;
  border-radius: 6.9vh;
  font-size: 2.2vh;
  padding: 1vh 4vh;
  margin-bottom: 3.5vh;
  color: #000000;
  display: flex;
  justify-content: center;
  align-content: center;
}

